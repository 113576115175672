
var famous          = require('famous');
var Node            = famous.core.Node;
var DOMElement      = famous.domRenderables.DOMElement;
var Align           = famous.components.Align;
var Scale           = famous.components.Scale;
var Origin          = famous.components.Origin;

function Logo(parent) {
    this.parent = parent;
    Node.call(this);
    
    this.origin = new Origin(this);
    this.scale = new Scale(this);
    this.align = new Align(this);
    
    this.setSizeMode('absolute', 'absolute')
        .setAbsoluteSize(378, 600)
        .setMountPoint(0.5, 0.5)
        .setPosition(0, 0, 1000);

    this.scale.set(0.1, 0.1);
    this.align.set(.5, .5);
    this.origin.set(.5, .5);

    this.el = new DOMElement(this, { tagName: 'img' })
        .setAttribute('src', './images/bookbound-logo.png')
        .setProperty('padding', '8px')
        // .setProperty('border', 'red 1px dotted')
        ;

    scaleBegin.call(this);
    alignLogo.call(this);

    this.addUIEvent('click');
}

Logo.prototype = Object.create(Node.prototype);

Logo.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'click') {
        setTop.call(this);
        this.emit('logoText.setTop');
        this.emit('cloud.goTranslucent');
        this.emit('books.init');
    }
}

function scaleBegin () {
    var duration    = 4000;
    this.scale.set(.01, .01);

    this.scale.set(1, 1, 1, {duration: duration, curve: 'outQuad'}, function(){
        scaleLogo.call(this);
    }.bind(this));
}

function scaleLogo () {
    var min         = .7;
    var max         = 1.1;
    var duration    = 1400;

    this.scaleTarget     = Math.random() * (max - min) + min;

    this.scale.set(this.scaleTarget, this.scaleTarget, 1, {duration: duration, curve: 'inOutQuad'}, function(){
        scaleLogo.call(this);
    }.bind(this));
}

function alignLogo () {
    var minX      = .35;
    var maxX      = .65;
    var minY      = .3;
    var maxY      = .6;
    var duration  = 4000;
    
    this.alignXTarget  = Math.random() * (maxX - minX) + minX;
    this.alignYTarget  = Math.random() * (maxY - minY) + minY;
    this.scaleDuration = 4000;

    this.align.set(this.alignXTarget, this.alignYTarget, 1, {duration: duration, curve: 'inOutQuad'}, function(){
        alignLogo.call(this);
    }.bind(this));
}

function setTop() {
    var duration = 1000;

    this.scale.halt();
    this.align.halt();
    this.origin.set(.5, .5);
    this.scale.set(.3, .3, 1, {duration: duration});
    this.align.set(.5, .1, 1, {duration: duration}, function(){
        this.emit('book.show');
    }.bind(this));
}

module.exports = Logo;

