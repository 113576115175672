var famous          = require('famous');
var FamousEngine    = require('famous/core/FamousEngine');
var Node            = famous.core.Node;
var Clock           = famous.core.Clock;
var DOMElement      = famous.domRenderables.DOMElement;
var Cloud 			= require('./Cloud');

function Clouds() {
    Node.call(this);

    this.clouds = [];

    this.el = new DOMElement(this);

    makeClouds.call(this);
}

Clouds.prototype = Object.create(Node.prototype);

function makeClouds () {
	var clock = FamousEngine.getClock();
	for (var i = 0; i < 36; i++) {
		clock.setTimeout(function(){
			addCloud.call(this);
		}.bind(this), Math.random() * 8000);
	}
}

function addCloud() {
	var cloud = this.addChild(new Cloud());
	this.clouds.push(cloud);
}

module.exports = Clouds;
