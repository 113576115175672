
var famous          = require('famous');
var Node            = famous.core.Node;
var DOMElement      = famous.domRenderables.DOMElement;
var Size            = famous.components.Size;
var Align           = famous.components.Align;
var Scale           = famous.components.Scale;
var Origin          = famous.components.Origin;

function LogoText() {

    Node.call(this);

    this.size = new Size(this);
    this.scale = new Scale(this);
    this.align = new Align(this);
    this.origin = new Origin(this);

    this.setSizeMode(1, 1);
    this.setAbsoluteSize(400, 80);
    this.setMountPoint(.5, .5);

    this.origin.set(.5, .5);
    this.scale.set(2, 2);
    this.align.set(.5, 1.2);

    this.el = new DOMElement(this)
        .setContent('Bookbound&nbsp;Publishing')
        // .setProperty('border', 'red 1px dotted')
        .addClass('papyrus');
    
}

LogoText.prototype = Object.create(Node.prototype);

LogoText.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'logoText.showLogoText') {
        showLogoText.call(this);
    }
    if (event === 'logoText.setTop') {
        setTop.call(this);
    }
}

function showLogoText () {
    var duration    = 1400;

    this.align.set(.5, .9, 1, {duration: duration, curve: 'outQuad'});
}

function setTop() {
    var duration = 1000;

    this.origin.set(.5, .5);
    this.scale.set(1, 1, 1, {duration: duration, curve: 'outQuad'});
    this.align.set(.5, .22, 1, {duration: duration});
}

module.exports = LogoText;

