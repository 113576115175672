
var famous          = require('famous');
var Node            = famous.core.Node;
var DOMElement      = famous.domRenderables.DOMElement;
var Align           = famous.components.Align;
var Scale           = famous.components.Scale;
var Opacity			= famous.components.Opacity;

var clouds = ['cloud-1', 'cloud-2', 'cloud-3'];

function Cloud() {

    Node.call(this);

	this.opacitateTo = 1;

    this.setSizeMode('absolute', 'absolute')
        .setAbsoluteSize(274, 187);
        
	this.setOrigin(0, .5);
	this.setMountPoint(.5, .5);
	this.setRotation(0, 10, 0);

	var idx = Math.round(Math.random() * (2-0) + 0);
	var cloudFile = clouds[idx];
	
    this.el = new DOMElement(this, { tagName: 'img' })
				    .setAttribute('src', './images/' + cloudFile + '.png');

    this.scale 		= new Scale(this);
    this.align 		= new Align(this);
	this.opacity 	= new Opacity(this);

	this.opacity.set(.01);

    resetCloud.call(this);
}

Cloud.prototype = Object.create(Node.prototype);

Cloud.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'cloud.goTranslucent') {
    	goTranslucent.call(this);
    }
}

function cloudCurve(progress) {
    return (Math.pow(progress, 2.2));
}

function resetCloud () {
	// x = cx + r * cos(a)
	// y = cy + r * sin(a)

	var scaleMin 	= 1;
	var scaleMax 	= 3.4;
	var duration 	= 10000;

	var radius      = .2;
	var angleMin	= 2.5;
	var angleMax	= 6.5;
	var angle       = Math.random() * (angleMax - angleMin) + angleMin;

	var ax 		= .5 + radius * Math.cos(angle);
	var ay 		= .5 + radius * Math.sin(angle);
	var eax 	= .5 + 1 * Math.cos(angle);
	var eay 	= .5 + 1 * Math.sin(angle);
	
	var scale 	= Math.random() * (scaleMax - scaleMin) + scaleMin;
	var dur     = Math.random() * (14000 - 8000) + 8000;

	this.opacity.set(.01, .01);
	this.align.set(ax, ay);
	this.scale.set(.4, .4);
	this.opacity.set(this.opacitateTo, {duration: duration/1.4});
	this.scale.set(scale, scale, 1, {duration: duration, curve: cloudCurve});
	this.align.set(eax, eay, 0, {duration: dur, curve: cloudCurve}, function () {
		this.opacity.set(.01)
		resetCloud.call(this);
	}.bind(this));
}

function goTranslucent() {
	var duration = 2000;
	this.opacitateTo = .15;
	this.opacity.halt();
	this.opacity.set(this.opacitateTo, {duration: duration});
}

module.exports = Cloud;
