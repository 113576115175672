
var famous          = require('famous');
var FamousEngine    = famous.core.FamousEngine;
var Node            = famous.core.Node;
var DOMElement      = famous.domRenderables.DOMElement;
var Opacity         = famous.components.Opacity;

var clock = FamousEngine.getClock();

function Testimonials() {
    Node.call(this);
    this.testimonials = [];
    this.curTestimonial = -1;
    this.opacity = new Opacity(this);

    this.setSizeMode(0, 1)
        .setAbsoluteSize(null, 200)
        .setProportionalSize(.8, null)
        .setOrigin(.5, 1)
        .setPosition(0, 0, 1000)
        .setMountPoint(.5, 1)
        .setAlign(.5, .94);

    this.el = new DOMElement(this)
        .addClass('papyrus')
        .setProperty('padding', '12px')
        .setProperty('font-size', '1.4em')
        // .setProperty('border', 'red 1px dotted')
        ;
}

Testimonials.prototype = Object.create(Node.prototype);

Testimonials.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'testimonials.set') {
        for (var i = 0; i < payload.length; i++) {
            this.testimonials.push(payload[i]);
        }
        
        showNext.call(this);
    }
}

function showNext() {
    if (this.testimonials.length > 0) {
        this.curTestimonial = (this.curTestimonial == this.testimonials.length - 1) ? 0 : this.curTestimonial + 1;
    } else {
        return;
    }

    this.opacity.set(0);

    var quote  = '&ldquo;' + this.testimonials[this.curTestimonial].quote + '&rdquo;';
    var byline =this.testimonials[this.curTestimonial].byline;

    if (byline.length > 0) {
        quote += '<div style="padding-top: 16px; padding-left: 24px;">- ' + byline + '</div>';
    }

    var quoteLen = quote.length;
    var split = 40 // Lower this to extend time for each quote, raise to shorten
    var timer = Math.round(quoteLen/split * 1000);

    console.log(quoteLen);

    
    console.log('timer: ' + timer);

    this.el.setContent(quote);



    this.opacity.set(1, {duration: 400}, function(){
        // make duration based on length of testimonial
        clock.setTimeout(function(){
            fadeCurrent.call(this);
        }.bind(this), timer);
    }.bind(this));
}

function fadeCurrent() {
    this.opacity.set(0, {duration: 1000}, function(){
        showNext.call(this);
    }.bind(this))

}




module.exports = Testimonials;

