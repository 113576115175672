var famous          = require('famous');
var Node            = famous.core.Node;
var Opacity         = famous.components.Opacity;
var DOMElement      = famous.domRenderables.DOMElement;

var Book            = require('./Book');

var books = [{'img': 'Laffy-book.jpg', 'size': [500, 658], 'posX': .25, 
              'testimonials': [{'quote': 'My children cannot stop talking about Laffy the Lamb. This story is inspirational. You can tell that Granny Bee took into account the developmental ages of the children who might be reading this story. I have learned that books are a great avenue for children and families to learn together. We should be grateful to Granny Bee for taking this into consideration.', 
                                'byline': 'Antonia Aikins, Ph. D., Psychologist and Mother of Two.'},
                                  {'quote': 'As a parent I am always looking for fun stories that have a good message.  I am very impressed with Laffy the Lamb.  My children love it too.',
                                   'byline': ''},
                                  {'quote': 'I love Laffy the Lamb.  I like how all of the animals helped him even though he was mean to them.', 
                                   'byline': 'Brianna, Age 4'},
                                  {'quote': 'Laffy the Lamb is a very clever story. My students loved it.',
                                   'byline': ''}
                                ]},
             {'img': 'SadSam-book.jpg', 'size': [500, 482], 'posX': .75,
              'testimonials': [{'quote': 'We&rsquo;ve all been a &ldquo;Sad Sam&rdquo; at one time or another. In this book, Stacy gives us &lsquo;Magic Cookies&rsquo; -- A time-honored solution to the blues, reminiscent of &lsquo;Stone Soup&rsquo;.  This beautifully written and illustrated book will be enjoyed by children and parents alike!',
                               'byline': 'Marc Wilmes-Reitz, Ph.D., Child Psychologist, and parent.'},
                                  {'quote': 'The other day when I was visibly upset, my six year old approached me and said, &lsquo;Mom, I think that you need to follow the Magic Cookie rule,&rsquo; I was impressed.', 
                                   'byline': 'Stacy Carson, Mom'},
                                  {'quote': 'Sad Sam and the Magic Cookies is a great self-help book for children.', 
                                   'byline': 'Larry Calemine, Parent'},
                                  {'quote': 'Stacy Quest does a terrific job of showing children how our thoughts can affect our actions.',
                                   'byline': 'John Gray, Ph.D., Author of &rsquo;Men are from Mars, Women are from Venus&lsquo; and &rsquo;Childen are from Heaven&lsquo;'},
                                  {'quote': 'This story is as sweet as the Magic Cookies -- without the calories.', 
                                   'byline': 'Maggie Gabriel, First Grade Teacher'},
                                ]}
         ];

function Books() {
    Node.call(this);
    
    this.setPosition(0, 0, 1000);
    
    this.el = new DOMElement(this);
    // this.el.setProperty('border', '1px solid green')
    ;
}

Books.prototype = Object.create(Node.prototype);

Books.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'books.init') {
        init.call(this);
    }
}

function init () {
    for (var book in books) {
        var b = this.addChild(new Book(books[book]));
    }
}

module.exports = Books;
