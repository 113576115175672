
var famous          = require('famous');
var Node            = famous.core.Node;
var DOMElement      = famous.domRenderables.DOMElement;
var Size            = famous.components.Size;
var Align           = famous.components.Align;
var Scale           = famous.components.Scale;
var Origin          = famous.components.Origin;
var Opacity         = famous.components.Opacity;

var books = [];

function Book(book) {
    Node.call(this);
    this.book = book;
    this.opacity = new Opacity(this);

    this.opacity.set(0);
    this.setSizeMode(1, 1);
    this.setAbsoluteSize(book.size[0], book.size[1]);
    // this.setProportionalSize(.5, .5);
    this.setMountPoint(.5, .5);
    this.setScale(.5, .5);
    this.setOrigin(.5, .5);
    this.setAlign(book.posX, .5);

    this.el = new DOMElement(this, { tagName: 'img' });
    this.el.setAttribute('src', './images/' + book.img);
    // this.el.setProperty('border', 'green 4px solid');

}

Book.prototype = Object.create(Node.prototype);

Book.prototype.onReceive = function onReceive (event, payload) {
    if (event === 'book.show') {
    	var duration = 2000;
        this.opacity.set(1, {duration: duration}, function(){
	        this.emit('testimonials.set', this.book.testimonials);
        }.bind(this));
    }
}

module.exports = Book;

