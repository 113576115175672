
module.exports = {
	Background: require('./Background'),
	Clouds: require('./Clouds'),
	Cloud: require('./Cloud'),
	Logo: require('./Logo'),
	LogoText: require('./LogoText'),
	Books: require('./Books'),
	Book: require('./Book'),
	Testimonials: require('./Testimonials'),
}
