'use strict';

// Famous dependencies
var famous = require('famous');
var FamousEngine = famous.core.FamousEngine;

var Bookbound = require('./Bookbound');
 
 //create the app and pass in the target element
var app = FamousEngine.createScene().addChild(new Bookbound());
