'use strict';

var DOMElement     = require('famous/dom-renderables/DOMElement');
var Node           = require('famous/core/Node');

function Background () {
    Node.call(this);

    this.el = new DOMElement(this);
    this.el.setProperty('background', 'url(./images/background.jpg) no-repeat center center fixed');
    this.el.setProperty('-webkit-background-size', 'cover');
    this.el.setProperty('-moz-background-size', 'cover');
    this.el.setProperty('-o-background-size', 'cover');
    this.el.setProperty('background-size', 'cover');
    this.el.setProperty('-webkit-background-size', 'cover');
}

Background.prototype = Object.create(Node.prototype);

module.exports = Background;

