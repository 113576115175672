'use strict';
var famous  = require('famous');
var FamousEngine = famous.core.FamousEngine;
var Node    = famous.core.Node;

var bookbound   = require('./index');
var Background  = bookbound.components.Background;
var Clouds		= bookbound.components.Clouds;
var Logo        = bookbound.components.Logo;
var LogoText    = bookbound.components.LogoText;
var Books       = bookbound.components.Books;
var Testimonials = bookbound.components.Testimonials;

function Bookbound() {
    // Extend Node
    Node.call(this);
	
	this.clock = FamousEngine.getClock();
    
	this.addChild(new Background());
	this.addChild(new Clouds());
	this.addChild(new LogoText());

	this.addChild()
		.setSizeMode(0, 1)
	    .setAbsoluteSize(null, 500)
	    .setOrigin(.5, .5)
	    .setMountPoint(.5, .5)
	    .setAlign(.5, .5)
	    .addChild(new Books());
	
	this.addChild(new Testimonials());

	this.clock.setTimeout(function(){
		this.addChild(new Logo(this));
	}.bind(this), 3000);

	this.clock.setTimeout(function(){
		this.emit('logoText.showLogoText');
	}.bind(this), 6000);
}

// Extend the prototype
Bookbound.prototype = Object.create(Node.prototype);

module.exports = Bookbound;
